<template>
  <div class="content" v-loading="loadingStatus.getTable">
    <div>
      <el-form ref="staffRef" size="medium" :model="dataForm" label-width="95px">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="所属工会：">
              <el-select style="width: 100%;" v-model="dataForm.presidentId" placeholder="请选择" clearable filterable @change="presidentIdChange">
                <el-option v-for="(item ) in presidentList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="小组：">
              <el-select style="width: 100%" v-model="dataForm.groupId" placeholder="请选择" clearable @change="groupIdChange">
                <el-option v-for="(item ) in groupList" :key="item.id"
                           v-show="item.presidentId === dataForm.presidentId || !dataForm.presidentId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="员工：">
              <el-select style="width: 100%" v-model="dataForm.stuffId" placeholder="请选择" clearable>
                <el-option v-for="(item) in empList" :key="item.id"
                           v-show="item.groupId=== dataForm.groupId || !dataForm.groupId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="游戏：">
              <el-select style="width: 100%" v-model="dataForm.gameId" placeholder="请选择" clearable>
                <el-option v-for="(item) in gamesList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="玩家角色名：">
              <el-input style="width: 100%" v-model="dataForm.gameUserRole" placeholder="请选择" clearable>
                <!-- <el-option v-for="(item) in userList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option> -->
              </el-input>
            </el-form-item>

          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="支付状态：">
              <el-select style="width: 100%" v-model="dataForm.paymentStatus" placeholder="请选择" clearable>
                <el-option v-for="(item) in optionEmployee" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="日期：">
              <el-date-picker
                  clearable
                  type="daterange"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  v-model="time"
                  @change="getTime"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <div style="display: inline-block;padding-left: 20px;padding-bottom: 20px">
            <el-button type="primary" @click="getTable" size="medium">查询</el-button>
            <el-button plain @click="getReset" size="medium">重置</el-button>
            <el-button type="warning" @click="onExport" size="medium">导出</el-button>
          </div>

        </el-row>

      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          @sort-change="sortChange"
          style="width: 100%">
        <el-table-column prop="presidentName" width="140" label="所属公会" align="center"></el-table-column>
        <el-table-column prop="orderId" width="180" label="订单号" align="center"></el-table-column>
        <el-table-column prop="gameUserId" label="用户账号" align="center" width="150"></el-table-column>
        <!-- <el-table-column prop="gameUserName" label="用户名" align="center"></el-table-column> -->
        <el-table-column prop="gameZoneId" label="区服ID" align="center"></el-table-column>
        <el-table-column prop="gameUserRole" label="玩家角色名" width="200" align="center"></el-table-column>
        <!-- <el-table-column prop="account" label="充值金额" align="center"></el-table-column> -->
        <el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
        <el-table-column prop="productId" label="商品ID" align="center"></el-table-column>
        <el-table-column prop="paymentWay" label="支付方式" align="center">
<!--          1、微信 2、支付宝-->
          <template slot-scope="scope">
            <span v-if="scope.row.paymentWay == 1">微信</span>
            <span v-else>支付宝</span>
          </template>
        </el-table-column>
        <el-table-column prop="paymentStatus" label="支付状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.paymentStatus == 1" style="color:lightgreen;">已支付</span>
            <span v-else style="color: red;">未支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="isNewPayment" width="100px" label="是否新支付" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.isNewPayment == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="paymentDatetime" width="160" label="支付时间" align="center"></el-table-column>
        <el-table-column prop="groupName"  width="100" label="小组名称" align="center"></el-table-column>
        <el-table-column prop="stuffName"  width="100" label="员工名称" align="center"></el-table-column>
        <el-table-column prop="promotionId"  width="180" label="推广ID" align="center"></el-table-column>
      </el-table>

      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import loadingStatusMixin from "@/mixins/loadingStatus";
import Excel from "@/util/excelGenerator";
import {commonInfo, dataStatisticsOrderDetailList} from "../../service/moudules/promotionLinkApi";

export default {
  name: "orderDetail",
  mixins: [loadingStatusMixin],

  data (){
    return {
      dataForm: {
        groupId: '', //组ID
        stuffId: '', //员工ID
        gameId: '', //游戏ID
        startDate: '', //开始时间
        endDate: '', //结果时间
        paymentStatus: '', //支付状态
        gameUserRole: '', //用户ID
        presidentId: '',
      },
      tableData: [],
      time: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      groupList: [], //小组
      empList: [], //员工信息
      gamesList: [], //游戏
      userList: [], //用户信息
      presidentList: [],
      optionEmployee: [
        {id: 1, name: '已支付'},
        {id: 2, name: '未支付'}
      ],
    }
  },
  mounted() {
    this.getTable();
    this.getCommonInfo();
  },
  methods:{
    presidentIdChange(value) {
        this.dataForm.groupId = ''
        this.dataForm.stuffId = ''
    },
    groupIdChange(value) {
        this.dataForm.stuffId = ''
    },
    async getCommonInfo() {
      let res = await commonInfo();
      if (res.code == 200) {
        this.groupList = res.data.groupList;
        this.empList = res.data.empList;
        this.gamesList = res.data.gamesList;
        this.userList = res.data.userList;
        this.presidentList = res.data.presidentList;
      }
    },
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataStatisticsOrderDetailList(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getReset() {
      this.dataForm = {
        groupId: '', //组ID
        stuffId: '', //员工ID
        gameId: '', //游戏ID
        startDate: '', //开始时间
        endDate: '', //结果时间
        gameUserRole: '', //用户ID
        presidentId: '',
      };
      this.time = [];
      this.getTable();
    },
    getTime(val) {
      if (val && val.length > 0) {
        let [x, y] = val;
        this.dataForm.startDate = x+' 00:00:00';
        this.dataForm.endDate = y+' 23:59:59';
      }else {
        this.dataForm.startDate = '';
        this.dataForm.endDate = '';
      }
    },
    async onExport() {
      if (this.hasLoadingState("onExport")) return;
      try {
        const params = {
          pageNo: 1,
          pageSize: this.page.total
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataStatisticsOrderDetailList(params);
        if (res.code === 200) {
          const columns = [
            { title: "序号", dataIndex: "index" },
            { title: "所属公会", dataIndex: "presidentName" },
            { title: "订单号", dataIndex: "orderId" },
            { title: "用户ID", dataIndex: "gameUserId" },
            { title: "用户名", dataIndex: "gameUserName" },
            { title: "区服ID", dataIndex: "gameZoneId" },
            { title: "角色", dataIndex: "gameUserRole" },
            { title: "订单金额", dataIndex: "amount" },
            { title: "商品ID", dataIndex: "productId" },
            { title: "支付方式", dataIndex: "paymentWay" },
            { title: "是否新支付", dataIndex: "isNewPayment" },
            { title: "支付时间", dataIndex: "paymentDatetime" },
            { title: "小组名称", dataIndex: "groupName" },
            { title: "员工名称", dataIndex: "stuffName" },
            { title: "推广ID", dataIndex: "promotionId" },
          ];
          const formData = (rawData) => {
            const data = rawData.map((item, index) => {
              const temp = {
                index: index + 1,
                presidentName: item.presidentName,
                orderId: item.orderId,
                gameUserId: item.gameUserId,
                gameUserName: item.gameUserName,
                gameZoneId: item.gameZoneId,
                gameUserRole: item.gameUserRole,
                amount: item.amount,
                productId: item.productId,
                paymentWay: item.paymentWay,
                isNewPayment: item.isNewPayment,
                paymentDatetime: item.paymentDatetime,
                groupId: item.groupId,
                stuffId: item.stuffId,
                promotionId: item.promotionId,
              };
              return temp;
            });
            return data;
          };
          const data = formData(res.data.records);
          const excel = new Excel({
            columns,
            data: data,
            tablename: "订单明细报表",
          });
          await excel.downFile('csv');
        }
      } catch (err) {
        console.error(err);
      }
    },
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header th.el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>